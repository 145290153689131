.adminLayout {
  &__link {
    text-decoration: none;
    color: rgb(77, 78, 87);
    padding: 0.31rem 1.87rem;
  }
  &__link_mobile {
    text-align: center;
    text-decoration: none;
    color: rgb(77, 78, 87);
    padding: 1rem 2.5rem;
    width: 100%;
    p {
      font-size: 24px;
    }
  }

  &__link_active {
    text-decoration: none;
    border: 1px solid rgb(77, 78, 87);
    color: rgb(77, 78, 87);
    background-color: #e9ecef;
    border-radius: 1.25rem;
    padding: 0.31rem 1.87rem;
    font-size: 1.12rem;
  }

  &__link_active_mobile {
    text-align: center;
    text-decoration: none;
    color: rgb(77, 78, 87);
    background-color: #e9ecef;
    padding: 1rem 2.5rem;
    width: 100%;
    p {
      font-size: 24px;
    }
  }
}
