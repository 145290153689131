@import "../../../../styles/variables";

.app {
    text-align: center;

    &__logo {
      $self: &;
      height: 40vmin;
      pointer-events: none;

      @media (prefers-reduced-motion: no-preference) {
          animation: rotate infinite 20s linear;
      }

    }

    &__header {
      background-color: #282c34;
      min-height: 100vh;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      font-size: calc(10px + 2vmin);
      color: white;
    }

    &__link {
      color: #61dafb;
    }

}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
